// Set options with default values
const options = {
	defaultTabId: "",
	activeClasses: "text-white",
	inactiveClasses: "text-navy",
};

const makeTabElements = (wrapper) => {
	[...wrapper].forEach((tabGroup) => {
		// create an array of objects with the id, trigger element (eg. button), and the content element
		const tabElms = tabGroup.querySelectorAll("[data-tabs-target]");
		const groupID = tabGroup.id;
		options.defaultTabId = `${groupID}-trigger-0`;
		var selectedTabId = 0;
		const allExtra = document.querySelectorAll(`[data-tab-group="${groupID}"]`);
		const tabElements = [...tabElms].map((tab, i) => {
			const tabObject = {
				id: `${groupID}-trigger-${i}`,
				triggerEl: tab,
				targetEl: document.querySelector(`#${groupID}-target-${i}`),
			};
			if (tab.getAttribute("aria-selected") == "true") selectedTabId = i;
			initExtraContent(tabObject);
			return tabObject;
		});
		options.defaultTabId = `${groupID}-trigger-${selectedTabId}`;

		const tabs = new Tabs(tabElements, options);
		controlExtraContent(tabs, allExtra);
	});
}; //Add support for multiple content elements

//Add support for multiple content elements
const initExtraContent = (object) => {
	const trigger = object.triggerEl;
	let elms = trigger.getAttribute("aria-controls").split(" ");
	if (elms.length > 1) {
		object.targetExtra = elms;
	}
};

const controlExtraContent = (tabObject, extraContent) => {
	tabObject._items.map((tab) => {
		if (tab.targetExtra) {
			const trigger = tab.triggerEl;
			tab.targetExtra.map((newTarget, i) => {
				if (i !== 0) {
					const newTargetNode = document.querySelector(newTarget);
					trigger.addEventListener("click", () => {
						extraContent.forEach((elm) => {
							if (!elm.classList.contains("hidden")) {
								elm.classList.add("hidden");
							}
						});
						newTargetNode.classList.remove("hidden");
					});
				}
			});
		}
	});
};

//Look for tabs
document.addEventListener("DOMContentLoaded", function () {
	const tabsOnPage = document.querySelectorAll("[data-tabs]");
	if (!tabsOnPage) return;
	makeTabElements(tabsOnPage);
});
